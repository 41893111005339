import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'LandingHome',
    component: () => import('@/views/Landing/Home')
  },
  {
    path: '/parks',
    name: 'Operator',
    component: () => import('@/views/Operator/Index'),
    children: [
      {
        path: '/parks',
        name: 'Parks',
        component: () => import('@/views/Operator/Parks')
      },
      {
        alias: '/parks/:park',
        path: '/parks/:park/attractions',
        name: 'Park',
        component: () => import('@/views/Operator/Park')
      },
      {
        path: '/parks/:park/attractions/:attraction',
        name: 'Attraction',
        component: () => import('@/views/Operator/Attraction')
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
