import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserFriends,
  faUser,
  faRulerHorizontal,
  faClock,
  faTrafficLight,
  faChartLine
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faUserFriends,
  faUser,
  faRulerHorizontal,
  faClock,
  faTrafficLight,
  faChartLine
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
